import { put, all, call, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import  * as actionTypes from './actions';
import {getToken} from 'src/utils/Cookies/Cookies';

const REACT_APP_BASIC_URI : string = (`${process.env.REACT_APP_BASIC_URI}pages` as string);

//TODO page 리스트 가져오기(PDF가 서버에 있을 경우)
function* pagesCallWorker (payload) {
  const config = getToken();
  try {
    const pages: AxiosResponse = yield call(() => axios.get(`${REACT_APP_BASIC_URI}?book=${payload.payload}`,config));
    yield put(actionTypes.pageSuccess(pages));
  } catch(err:any) {
    yield put(actionTypes.pageError(err));
  }
}

export function* getPagesSaga() {
  
  yield all([
    takeLatest(actionTypes.PAGE_INIT , pagesCallWorker),
    
  ]); 
}