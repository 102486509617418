import React,{ useState, useEffect,useRef} from 'react';
import LabCodeItem from './LabCodeItem';
interface Props{
  handleClickDefaultSetting
  handleClickPartialSetting
  selectTool
  setSelectTool
  transform
  setTransform
  isDefaultSetting
  handleClickSection
  sectionData
  handleClickDeleteModal
  KeyPressSectionTitle
  isListItem
  setIsListItem
  selectSectionTool
  setSelectSectionTool
  isPartialSetting
  setIsPartialSetting
  partialRef
 }
 
const RightSideBar: React.FC<Props> = (props: Props) =>{
  const [isInputState, setIsInputState] = useState(false);
  const itemInputRef = useRef<HTMLInputElement | null>(null);
  const [itemInput, setItemInput] = useState('영역');
  
  useEffect(() => {
  //TODO x축 값이 있으면 영역 제목 부분 활성화 및 focus
    if(props.transform.x !== 0){
      props.setIsListItem(true);
      setIsInputState(true);
      if(props.selectTool === 'square_draw'){
        setTimeout(() => {itemInputRef.current?.focus()},200)
      }
      
    }else{
      props.setIsListItem(false);
    }
  },[props.setSelectTool, props.transform]);
    // 영역등록 클릭
  const handleClickregistration = () => {
    setItemInput('');
    props.handleClickSection(props.transform,itemInput)
  }
  return (
  
    <div className="screen-right-sideBar">
     <div className="screen-right-sideBar-btn">
          <button  className={props.selectTool === "select" ? "mybutton selected tooltip-btn" : "mybutton tooltip-btn"} onClick={() => props.setSelectTool('select')}>
              <div className="select-btn" id="selectBtn" />
              <div className='tooltip tooltip-center' style={{top: 45,right: -15}}>선택 도구</div>
          </button>
          <button className={props.selectTool === "square_draw" ? "mybutton selected tooltip-btn" : "mybutton tooltip-btn"} onClick={() => props.setSelectTool('square_draw')}>
              <div className="square_draw_btn" style={{width:14,height:14}}/>
              <div className='tooltip tooltip-center' style={{top: 45,right: -15}}>사각형 도구</div>
          </button>
        </div>
     <div className="transform">  
          <div className="transform-title">트랜스폼</div>
          <div className="transform-axis">
              <div className="transform-X-axis"><span>X</span><input type="number" value={props.transform.x} disabled/></div>
              <div className="transform-width"><span>W</span><input type="number" value={props.transform.width} disabled/></div>
              <div className="transform-Y-axis"><span>Y</span><input type="number" value={props.transform.y} disabled/></div>
              <div className="transform-height"><span>H</span><input type="number" value={props.transform.height} disabled/></div>
          </div>
      </div>
      <div className="default-setting-btn" ><div>기본 설정</div><button className="setting-open-btn tooltip-btn" style={{background: props.isDefaultSetting ? "#252a35" : ""}}onClick={props.handleClickDefaultSetting}>
      <div className='more'/><div className='tooltip tooltip-right'>기본 설정</div></button></div>
      <button className="registration" onClick={() => handleClickregistration()} disabled={!(props.isListItem)}>영역 등록</button>
      <div className='labCode-list'>
       
          { props.isListItem  ? 
          <div className='labCode-list-item '>
          <div className='labCode-list-item-input' onDoubleClick={() => setIsInputState(true)}>
            {isInputState ? 
            <input ref={itemInputRef} style={{width:'220px'}} value={itemInput} onChange={e=>setItemInput(e.target.value)} />
            :<div style={{paddingLeft: 8}} >{itemInput}</div>
            }           
          </div>
            </div>
            : <div></div>
          }
          
          {props.sectionData?.map((item, index) => {
          
            return (
              <LabCodeItem 
                key={index}
                index={index} 
                item={item} 
                handleClickPartialSetting={props.handleClickPartialSetting} 
                handleClickDeleteModal={props.handleClickDeleteModal} 
                KeyPressSectionTitle={props.KeyPressSectionTitle}
                selectSectionTool={props.selectSectionTool}
                setSelectSectionTool={props.setSelectSectionTool}
                isPartialSetting={props.isPartialSetting}
                setIsPartialSetting={props.setIsPartialSetting}
                partialRef={props.partialRef}
                />
            )
          })}
      </div>
      {/* <div className='setting-apply'><div className='setting-apply-btn'>적용하기</div></div> */}
    </div>
  )
  
};
export default RightSideBar