import React,{ useState, useEffect,useRef } from 'react';

interface Props{
  open
  handleClickDefaultSetting
  settingData
  sectionItem,
  setSectionItem
 }
 
const DefaultSetting: React.FC<Props> = (props: Props) =>{
  const [embeddingOption, setEmbeddingOption] = useState(false);
  const [channelOption, setChannelOption] = useState(false);
  const channelEl = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
  const embeddingEl = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
  //TODO 임배딩 리스트 클릭
  const embeddingOptionClick = (value) => {
    settingChange("embedding",value)
    setEmbeddingOption(false)
  }
  //TODO 채널 리스트 클릭
  const channelOptionClick = (value) => {
    settingChange("channel",value)
    setChannelOption(false)
  }
  //TODO 셋팅 값 변경
  const settingChange = (title,value) => {
  
    switch (title) {
      case "scale":
        props.setSectionItem({...props.sectionItem,scale:value});
      break;
      case "alpha":
        props.setSectionItem({...props.sectionItem,alpha:value});
      break;
      case "url":
        props.setSectionItem({...props.sectionItem,url:value});
      break;
      case "embedding":
        props.setSectionItem({...props.sectionItem,embedding:value});
      break;
      case "channel":
        props.setSectionItem({...props.sectionItem,channel:value});
      break;
      default:
    }
    
    }
  useEffect(() => {
    //select list open 중 다른 화면 클릭시 close
    const handleCloseModal= (e: React.BaseSyntheticEvent | MouseEvent) => {
      if (!channelEl.current?.contains(e.target)) setChannelOption(false);
      if (!embeddingEl.current?.contains(e.target)) setEmbeddingOption(false);
     }
     
     document.addEventListener('click',handleCloseModal,true);
    return () => {
      document.addEventListener('click',handleCloseModal,true);
    }
  }, [channelEl,embeddingEl])
  
  return (
      <div className='setting' style={{display: props.open ? "block" : "none"}}>
        <div className='setting-title'><div>기본 설정</div> <div onClick={props.handleClickDefaultSetting} style={{cursor:'pointer'}}><div className='slurp' /></div></div>
        <div className='setting-content' style={{height:450}}>
          <div className="setting-text">링크</div>
          <div className="setting-item"><input type="text" defaultValue={props.sectionItem.url}/><a href={props.sectionItem.url} rel="noreferrer" target="_blank" ><div className='url' /></a></div>
          <div className="setting-text">임베딩 버전</div>
          <div className="setting-item">
            <div className="select" >
            <div className="select-btn" onClick={() => setEmbeddingOption(true)}>
            v {props.sectionItem.embedding}
            <div className='select-arrow' />
            </div>
            <div className="optionGroup" ref={embeddingEl} style={{display:embeddingOption ? 'block' : 'none'}}>
            {props.settingData?.embedding.map((item, index) => { 
              return (
                <div className="option" onClick={() => embeddingOptionClick(item.value)} key={index}> {item.label} {props.sectionItem.embedding === item.value && <div className='check' />}
                </div>
              )
              })}
          </div>
            </div>
           
          </div>
          <div className="setting-text">적용기술</div>
          <div className="setting-item">
            <div className="select">
            <div className="select-btn" onClick={() => setChannelOption(true)}>
            {props.settingData?.channel.map((item, index) => {
            if(item.value === props.sectionItem.channel)
              return(item.label)
            })}
            <div className='select-arrow' />
            </div>
            <div className="optionGroup" ref={channelEl} style={{display:channelOption ? 'block' : 'none'}}>
            {props.settingData?.channel.map((item, index) => {
              return (
                <div className="option" onClick={() => channelOptionClick(item.value)} key={index}> {item.label} {props.sectionItem.channel === item.value && <div className='check' />}
                </div>
              )
              })}
          </div>
            </div>
           
          </div>
          <div className="setting-code" style={{marginTop:32}}> <div className="setting-text">코드크기 </div><input type="number" value={props.sectionItem.scale} onChange={e => settingChange('scale',Number(e.target.value))} /></div>
          <div className="setting-value">
          {props.settingData?.scale.map((item, index) => {
              return (
                <div className={props.sectionItem.scale === item.value ? "setting-btn selected" :  "setting-btn"} onClick={() => settingChange('scale',item.value)} key={index}>{item.label} {item.value}</div>
              )
              })}
          </div>
          <div className="setting-code"> <div className="setting-text">코드세기 </div><input type="number" value={props.sectionItem.alpha} onChange={e => settingChange('alpha',(Number(e.target.value)))}/></div>
          <div className="setting-value">
          {props.settingData?.alpha.map((item, index) => {
              return (
                <div className={props.sectionItem.alpha === item.value ? "setting-btn selected" :  "setting-btn"} onClick={() => settingChange('alpha',item.value)} key={index}>{item.label} {item.value}</div>
              )
              })}
          </div>
        </div>
        {/* <div className='flex-center setting-actions'>
            <button className='setting-save' style={{width:208}} disabled={false} >저장</button>
          </div> */}
      </div>
    
  )
  
};
export default DefaultSetting