import React,{ useState,useEffect,useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerType } from 'src/modules';
import { sectionSettingInit,sectionCreateInit,sectionInit,sectionDeleteInit,sectionUpdateInit } from 'src/modules/Sections';
import { bookIdInit } from 'src/modules/Books';
import { PDFInit, PDFCreateInit } from 'src/modules/PDF';
import { pageInit } from 'src/modules/Pages';
import { saveAs } from 'file-saver';

import FileModal from 'src/components/FileModal';
import DownloadModal from "src/components/DownloadModal";
import ScreenHeader from './ScreenHeader';
import DefaultSetting from "./DefaultSetting";
import PartialSetting from './PartialSetting';
import ScreenContainer from './ScreenContainer';
import Loading from 'src/components/Loading';
import ExportModal from 'src/components/ExportModal';
import DeleteModal from 'src/components/DeleteModal';
import ErrorModal from 'src/components/ErrorModal';
import RightSideBar from'./RightSideBar';
import LeftSideBar from './LeftSideBar';

interface SectionList {
  alpha: number,
  channel: string,
  current_page: number,
  embedding: string,
  h: number,
  id: number,
  image_converted: string
  image_original: string,
  scale: number,
  title: string,
  url: string,
  w: number,
  x: number,
  y: number
}

const Screen = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isFileOpen, setIsFileOpen] = useState(true);
  const [isListItem, setIsListItem] = useState(false);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [isDefaultSetting, setIsDefaultSetting] = useState(false);
  const [isPartialSetting, setIsPartialSetting] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [selectSectionId,setSelectSectionId] = useState(0);
  const [selectSectionTool,setSelectSectionTool] = useState(-1);
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [PDFexport, setPDFexport] = useState(null);
  const [selectPage,setSelectPage] = useState({book: 0,
    id: 0,
    image: "",
    image_height: 0,
    image_width: 0,
    number: 1});
  const [selectTool, setSelectTool] = useState('select');
  const [transform,setTransform] = useState({x:0,y:0,width:0,height:0});
  const ScreenId = (window.location.pathname).split("/")[3]
  const BookIdState = useSelector((state: RootReducerType) => state.books);
  const SettingIdState = useSelector((state: RootReducerType) => state.sections);
  const PageState = useSelector((state: RootReducerType) => state.pages);
  const PDFState = useSelector((state: RootReducerType) => state.PDF);
  const [pdfList, setPdfList] = useState([]);
  const [selectImg, setSelectImg] = useState();
  const [selectSectionImg, setSelectSectionImg] = useState();
  const [sectionList, setSectionList] = useState<SectionList[]>([]);
  const partialRef = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
  const [pdfState, setPdfState] = useState({
    file: null,
    numPages: 0,
    pageNumber: 1
  });
  
  const [selectSectionItem,setSelectSectionItem] = useState({
    title: "",
    url: "https://www.naver.com",
    image_original: "",
    embedding: "0.0",
    channel: "lab",
    scale: 2,
    alpha: 6,
    x: 0,
    y: 0,
    h: 0,
    w: 0,
    id:0
    });
    
  const [sectionItem,setSectionItem] = useState({
    title: "",
    url: "https://www.naver.com",
    image_original: "",
    embedding: "0.0",
    channel: "lab",
    scale: 2,
    alpha: 6,
    x: 0,
    y: 0,
    h: 0,
    w: 0,
    id:0
    });
  const [errorModal, setErrorModal] = useState({
    open:false,
    title:"",
    content:""
  });
  
  //서버 호출
  useEffect(() => {
      dispatch(bookIdInit(ScreenId));   
      dispatch(sectionSettingInit());
      dispatch(pageInit(ScreenId));
      dispatch(PDFInit(ScreenId));
    
  }, [ScreenId, dispatch]); 
  
  const { loading, getData } = BookIdState;
  const { sectionLoading, settingData, sectionData } = SettingIdState;
  const { pageLoading, pageData } = PageState;
  const { PDFloading,PDFdata,PDFDownload } = PDFState;
  
  console.log('pageData',pageData)
  console.log('PDFdata',PDFdata)
   //TODO 서버에러
  useEffect(()=>{
    if(BookIdState.error || SettingIdState.error || PageState.error || PDFState.error){
      setErrorModal({
        open:true,
        title:"서버 응답을 받지 못했습니다.",
        content:"일시적인 현상이거나 네트워크 문제일 수 있으니 잠시 후 다시 시도해주세요."
    })
    
    };
  },[BookIdState,SettingIdState,PageState,PDFState]);
  
   //TODO 서버로딩
   useEffect(()=>{
    if(loading || sectionLoading || pageLoading || PDFloading){
      setIsLoading(true)
    }else{
      setIsLoading(false)
    }
  },[PDFloading, loading, pageLoading, sectionLoading]);
  
  //TODO 서버에 있는 pdf 데이터를 가져올때
  useEffect(() => {
    if(pageData !== null){
      if(pageData?.data.result.length !== 0){
        setIsFileOpen(false);
        setPdfList(pageData?.data.result);
        setSelectPage(pageData?.data.result[0]);
        dispatch(sectionInit(pageData?.data.result[0].id));
        dispatch(PDFInit(ScreenId));
        setSelectImg(pageData?.data.result[0].image);
      }
    }

  },[pageData])

  const sectionFilter = () => {
  setSectionList([])
    sectionData?.result.map((item,index)=> {
      if(item.current_page === selectPage?.id){
        setSectionList(oldItem => [...oldItem,item]);
      }
    })
  }
  useEffect(() => {
    sectionFilter();
  },[sectionData, selectPage])
  
  //TODO 첫 PDF 삽입시
  useEffect(() => {
    if(PDFdata !== null){
      if(PDFdata?.result){
        if(PDFdata?.result.length !== 0){
          setIsFileOpen(false);
          setPdfList(PDFdata?.result);
          setSelectPage(PDFdata?.result[0]);
          dispatch(sectionInit(PDFdata?.result[0].id));
          setSelectImg(PDFdata?.result[0].image);
        }
      }
    }
  },[PDFdata, PDFloading, dispatch, loading]);
  
  //TODO 내보내기 pdf 데이터 
  useEffect(() => {
  
    if(PDFDownload !== null){
      if(PDFDownload?.result.length !== 0){
      setPDFexport(PDFDownload?.result[0].pdf);
      }
    }
  },[PDFDownload]);
  
  // PDF 삽입
  const onFileChange = event => {
    const formData = new FormData();
    formData.append("book",ScreenId);
    formData.append("pdf",event.target.files[0]);
    setIsLoading(true);
    try {
    dispatch(PDFCreateInit(formData));  
    setIsLoading(false);
   
  } catch(err:any) {
    setIsLoading(false);
  }
    setPdfState({
      ...pdfState,
      file: event.target.files[0]
    });
    handleFileModal();
  };
  
  //TODO pdf list 클릭
  const hanldleClickPage = (e,index,id) => {
    setPdfState({
    ...pdfState,
      pageNumber: index
    });
    const list = e.target.closest('.MovableList').parentElement.children
    for (let sib of list) {
        sib.classList.remove('select')
    }
    e.target.closest('.MovableList').classList.add('select')
    setSelectPage(id);
    sectionFilter()
  };
  
  const importClicked = () => {
    document.getElementById("ScreenPdf")?.click();
    
  }
  //TODO 파일 첨부 팝업 여닫기
  const handleFileModal = () => {
    setIsFileOpen(!isFileOpen);
  }
  //TODO 내보내기 팝업 여닫기
  const handleDownloadModal = () => {
    setIsDownloadOpen(!isDownloadOpen);
  }
  //TODO 내보내기 pdf save
  const handleClickSaveFile = async (e:string) => {
  
    handleDownloadModal()  
    setIsExportOpen(true)
  
   if(PDFexport !== null) {
     saveAs(PDFexport, `${e}.pdf`);
     setIsExportOpen(false);
   }else{
    dispatch(PDFInit(ScreenId));
   }
  }
  //TODO 영역 생성
  const handleClickSection = (transform,title) => {
    
    const section = {
      current_page: selectPage?.id,
      title: title,      
      url: sectionItem.url,
      embedding: sectionItem.embedding,
      channel: sectionItem.channel,
      scale: sectionItem.scale,
      alpha: sectionItem.alpha,
      x: transform.x - transform.width,
      y: transform.y - transform.height,
      h: transform.height,
      w: transform.width
    }
    
    setIsDefaultSetting(false);
    dispatch(sectionCreateInit(section));
    dispatch(PDFInit(ScreenId));
    document.getElementById("selectBtn")?.click();
    setTransform({x:0,y:0,width:0,height:0});
  }
  //TODO 영역 삭제
  const handleClickSectionDelete = () => {
    dispatch(sectionDeleteInit(selectSectionId));
    setDeleteModalOpen(!DeleteModalOpen);
    setTimeout(()=>{document.getElementById(`MovableList-${selectPage?.id}`)?.click()},300)
  }
  //TODO 기본 설정 여닫기
  const handleClickDefaultSetting = ()=>{
    setIsPartialSetting(false);
    setIsDefaultSetting(!isDefaultSetting);
  }
  //TODO 개별 설정 여닫기
  const handleClickPartialSetting = (item)=>{
    setIsDefaultSetting(false);
    if(item){
      setSelectSectionId(item.id)
      setSelectSectionItem(item)
    }
    setIsPartialSetting(!isPartialSetting)
  }
  //TODO 영역 삭제 팝업 여닫기
  const handleClickDeleteModal = (e) => {
    setSelectSectionId(e)
    setDeleteModalOpen(!DeleteModalOpen)
  }
  //TODO 영역 이름 변경
  const KeyPressSectionTitle= (e,title,id)=>{
    const updateSection={
      id:id,
      title:title,
      key:'title'
    }
    if(e.key === "Enter"){
      if(!e){
        alert("책제목을 입력해주세요");
        return;
      }
      dispatch(sectionUpdateInit(updateSection));
      (document.activeElement as HTMLElement).blur();
    }
  }
  //TODO 개별 설정 저장
  const settingSave = () => {
    
    const settingData={
      url: selectSectionItem.url,
      embedding: selectSectionItem.embedding,
      channel: selectSectionItem.channel,
      scale: selectSectionItem.scale,
      alpha: selectSectionItem.alpha,
      id:selectSectionItem.id,
    }
    dispatch(sectionUpdateInit(settingData));
    setIsPartialSetting(false);
  }
    //에러 팝업 확인 버튼 기능
  const handleErrorModal = (title) =>{
    setErrorModal({
      ...errorModal,
      open:false,
    })
  }
  
  if(isLoading) return <Loading />
  
  return (
  
  <div className='screen'>
    <ScreenHeader handleDownloadModal={handleDownloadModal} handleSaveModal={handleDownloadModal} title={getData?.result.title}/>
    <div className='screen-container'>
      <LeftSideBar 
      pdfList={pdfList}
      hanldleClickPage={hanldleClickPage}
      selectImg={selectImg}
      setSelectImg={setSelectImg}
      selectPage={selectPage}
      />
      <ScreenContainer 
        selectTool={selectTool}
        setTransform={setTransform}
        sectionData={sectionList}
        selectSectionTool={selectSectionTool}
        selectImg={selectImg}
        setSelectSectionImg={setSelectSectionImg}
        selectPage={selectPage}
        onFileChange={onFileChange}
        />
     <RightSideBar 
        handleClickDefaultSetting={handleClickDefaultSetting} 
        handleClickPartialSetting={handleClickPartialSetting} 
        selectTool={selectTool}
        transform={transform}
        setTransform={setTransform}
        setSelectTool={setSelectTool}
        isDefaultSetting={isDefaultSetting}
        isPartialSetting={isPartialSetting}
        handleClickSection={handleClickSection}
        sectionData={sectionList}
        handleClickDeleteModal={handleClickDeleteModal}
        KeyPressSectionTitle={KeyPressSectionTitle}
        isListItem={isListItem}
        setIsListItem={setIsListItem}
        selectSectionTool={selectSectionTool}
        setSelectSectionTool={setSelectSectionTool}
        setIsPartialSetting={setIsPartialSetting}
        partialRef={partialRef}
        />
      </div>
    <DefaultSetting open={isDefaultSetting} handleClickDefaultSetting={handleClickDefaultSetting} settingData={settingData?.result} sectionItem={sectionItem} setSectionItem={setSectionItem}/>
    <PartialSetting open={isPartialSetting} handleClickPartialSetting={handleClickPartialSetting} settingData={settingData?.result} settingSave={settingSave} selectSectionItem={selectSectionItem} setSelectSectionItem={setSelectSectionItem} sectionItem={sectionItem} partialRef={partialRef}/>
    <FileModal open={isFileOpen} handleFileModal={handleFileModal} importClicked={importClicked} />
    <DownloadModal open={isDownloadOpen} handleSaveModal={handleDownloadModal} handleClickSaveFile={e => handleClickSaveFile(e)} numPages={pdfList.length} />
    <ExportModal open={isExportOpen} ExportModalBtn={() => setIsExportOpen(!isExportOpen)} numPages={pdfList.length} />
    <DeleteModal 
        title="생성된 영역 삭제" 
        content="관련 데이터가 모두 삭제됩니다." 
        isOpen={DeleteModalOpen} 
        handleDeleteModal={() => setDeleteModalOpen(!DeleteModalOpen)} 
        deleteFunction={handleClickSectionDelete} 
      />
   <ErrorModal 
      title={errorModal.title} 
      content={errorModal.content}
      errorModalOpen={errorModal.open} 
      handleErrorModal={handleErrorModal}
      />
  </div>
  )
  
};
export default Screen