import React,{ useState,useRef,useEffect } from 'react';
import KonvaCanvasdraw from './KonvaCanvasdraw.js';
import Cropperjs from './Cropper';
import ReactDataGrid  from './ReactDataGrid.js'
interface Props{
    onFileChange
    selectTool
    setTransform
    sectionData
    selectSectionTool
    selectImg
    setSelectSectionImg
    selectPage
   }

const ScreenContainer: React.FC<Props> = (props: Props) =>{
  const screenContainer = useRef<HTMLDivElement | null>(null);
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [sectionDataImg, setSectionDataImg] = useState([{w:0,h:0,x:0,y:0,image_original:"",title:""}])
  
  useEffect(() => {
    setScreenWidth(Number(screenContainer.current?.offsetWidth));
    setScreenHeight(Number(screenContainer.current?.offsetHeight));

  },[])
  useEffect(() => {
    setSectionDataImg(props.sectionData)
    
  },[props.sectionData])
  
  return (
    <div className='screen-center-container' ref={screenContainer} >
      <input type="file" id="ScreenPdf" onChange={props.onFileChange} accept=".pdf" />
     
      <KonvaCanvasdraw selectTool={props.selectTool} setTransform={props.setTransform} KonvaWidth={screenWidth} KonvaHeight={screenHeight}/>
      {/* <Cropperjs selectImg={props.selectImg} setTransform={props.setTransform} selectTool={props.selectTool} setSelectSectionImg={props.setSelectSectionImg}/> */}
      <div className="pdfImage">
      {/* <ReactDataGrid /> */}
      <div>
        {sectionDataImg && sectionDataImg.map((item, index)=>{
          return(<div className={index === props.selectSectionTool ? 'serverImg select': 'serverImg'} key={index} style={{width:item.w,height:item.h,top:item.y,left:item.x}}></div>)
        })}
      </div>
      <div className='flex-center'>
      {props.selectImg && <img src={props.selectImg} alt="selectImg" style={{width:props.selectPage.image_height>props.selectPage.image_width ? '65vh' : '65vw'}}/> }
        
      </div>
    </div> 
  </div>
  )
  
};
export default ScreenContainer
