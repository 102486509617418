import React, {useRef,useState,useEffect,useCallback} from 'react'
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import _ from "lodash";
import { RootReducerType } from 'src/modules';
import { bookPageInit,bookCreateInit,bookUpdateInit,bookDeleteInit } from 'src/modules/Books';
import { logoutRequest } from 'src/modules/Login';
import Container from './Container';
import DeleteModal from 'src/components/DeleteModal';
import ErrorModal from 'src/components/ErrorModal'
import Loading from 'src/components/Loading';

import 'src/assets/fonts/font.css'
import 'src/assets/scss/reset.scss'
import 'src/assets/scss/Books.scss'
import 'src/assets/scss/Pages.scss'

const Books = () => {
  const history = useHistory();  
  const dispatch = useDispatch();
  const CreateFileRef = useRef<HTMLInputElement | null>(null);
  const UpdataFileRef = useRef<HTMLInputElement | null>(null);
  const BookState = useSelector((state: RootReducerType) => state.books);
  const [scrollLoading, setScrollLoading] = useState(false)
  const [workBooksListId, setWorkBooksListId] = useState<string | null>();
  
  const [DeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [BookTitle, setBookTitle] = useState<string | null>();
  const [errorModal, setErrorModal] = useState({
    open:false,
    title:"",
    content:""
  });
  
  useEffect(() => {
  //TODO book list 조회
    dispatch(bookPageInit());   
    
  }, [dispatch]); 

  
  const { loading, data, error } = BookState;
  
  //TODO 서버에러
  useEffect(()=>{
  console.log('error',error)
  
    if(error){
    if(error.response?.status === 403){
      setErrorModal({
        open:true,
        title:"로그아웃 되었습니다.",
        content:`로그인 토큰이 만료되어 연결이 끊어졌습니다.\r\n 다시 로그인해주세요.`
      })
    }else{
      setErrorModal({
        open:true,
        title:"서버 응답을 받지 못했습니다.",
        content:"일시적인 현상이거나 네트워크 문제일 수 있으니\r\n 잠시 후 다시 시도해주세요."
      })
    }
    }else{
      setErrorModal({
      ...errorModal,
        open:false,
      })
    }
  },[error]);

  //TODO BOOK 생성 
  const handleClickCreateFile = () =>{
    if(!CreateFileRef.current) return;
    CreateFileRef.current.click();
  }
  
  const CreateBook = (event) => {
    if(!event.target.files[0]) return;
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
    const newBooks = {
        category:"WORKBOOK",
        image_cover:reader.result
      };
      setScrollLoading(true);
      dispatch(bookCreateInit(newBooks));
      setTimeout(() =>{setScrollLoading(false);},1000)
    }
  };

  //TODO BOOK 이미지 변경
  const handleClickUpdataFile = useCallback((e: any, id:any) =>{
    e.stopPropagation();
     setWorkBooksListId(id)
     if(!UpdataFileRef.current) return;
     UpdataFileRef.current.click();
  },[]);
  
  const UpdateBookImage = (event: any) => {
    if(!event.target.files[0]) return;
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
    const updateBook = {
        id:workBooksListId,
        image_cover:reader.result
      };
      dispatch(bookUpdateInit(updateBook));
    }
  };

    //TODO BOOK 제목 변경
    const ChangeBooksTitle =(e: any) => {
      setBookTitle(e.target.value);
  }
  //TODO BOOK 제목 변경
  const KeyPressBooksTitle= (e:any, id:any)=>{
    const updateBook={
      id:id,
      title:BookTitle
    }
    if(e.key === "Enter"){
      if(!BookTitle){
        alert("책제목을 입력해주세요");
        return;
      }
      dispatch(bookUpdateInit(updateBook));
      (document.activeElement as HTMLElement).blur();
    }
  }
  
  //TODO BOOK DELETE
  const bookDeeteModal = (e:any, id:any) =>{
    e.stopPropagation();
    handleDeleteModal();
    setWorkBooksListId(id);
  }

  const bookDeete = (id:any) =>{
    handleDeleteModal();
    const deleteId = {
      id:workBooksListId
    }
    dispatch(bookDeleteInit(deleteId));
  }

  //TODO Books 클릭시 페이지 이동
  const handleClickBook = (id:any) => {
    // history.push(`/books/pages/${id}`);
    history.push(`/books/screen/${id}`);    
  }

  const handleDeleteModal = () => {
    setDeleteModalOpen(!DeleteModalOpen);
  }
  const handleErrorModal = (title) =>{
    setErrorModal({
      ...errorModal,
      open:false,
    })
    if(title.indexOf('403') === -1 ) logOut();
  }
  const logOut = () => {
    dispatch(logoutRequest());
  }
if(loading) return <Loading />

  return (
    <div className="main">
      <div className="main-header">
        <div className='logo'>
          <img src={require("src/images/pages/logo.svg").default} alt="logo" />
        </div>
        <div className='logOut' onClick={logOut}>로그아웃</div>
      </div>
      <div className="main-content">
      <div className="sideBar">
        <div className="list activate"><div className="img"></div><div>문서</div></div>
      </div>
     
       <div className="container" id="scroller">
        <div className="container-card createFile" style={{backgroundColor : '#b3b8c0'}} onClick={handleClickCreateFile}><div className="plus" ></div>
        <input type="file" name="CreateFile" ref={CreateFileRef}  accept=" .jpg, .jpeg, .png" onChange={CreateBook}/>
        </div>
            {data?.map((item,index) =>{
              // if(index < pageNumber * 20)
                return(
                    <Container 
                    item={item}
                    index={index}
                    handleClickBook={(e) => handleClickBook(e)}
                    handleClickUpdataFile={handleClickUpdataFile} 
                    UpdataFileRef={UpdataFileRef}
                    bookDeeteModal={bookDeeteModal}
                    key={index}
                    ChangeBooksTitle={ChangeBooksTitle}
                    KeyPressBooksTitle={KeyPressBooksTitle}
                    />
                    
                ) 
            }
            )}
           <input type="file" name="UpdateFile" ref={UpdataFileRef}  accept=".jpg, .jpeg, .png" onChange={UpdateBookImage}/>
           {scrollLoading && (
          <Loading />
        )}
       </div>
       </div>
       <DeleteModal 
          title="문서 삭제" 
          content="관련 데이터가 모두 삭제 됩니다." 
          isOpen={DeleteModalOpen} 
          handleDeleteModal={handleDeleteModal} 
          deleteFunction={bookDeete} 
        />
       <ErrorModal 
          title={errorModal.title} 
          content={errorModal.content}
          errorModalOpen={errorModal.open} 
          handleErrorModal={handleErrorModal}
          />
      
    </div>
    
  );
};

export default (Books)