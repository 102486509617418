import  * as actionTypes from './actions';
import { AxiosError } from 'axios';

type State = {
  PDFloading: boolean;
  PDFdata: {[key:string]:any} | null;
  PDFDownload: {[key:string]:any} | null;
  error: AxiosError | null;
};

const initiaState = {
  PDFloading: false,
  PDFdata: null,
  PDFDownload:null,
  error: null
};

export default function PDF(
  state: State = initiaState,
  action: actionTypes.GetPDFActionsType
): State {
  switch (action.type) {
    case actionTypes.PDF_INIT:
      return {
        ...state,
        PDFloading: true,
        PDFdata:null
      };
    case actionTypes.PDF_SUCCESS:
      return {
        ...state,
        PDFloading: false,
        PDFdata: action.payload
      };
    case actionTypes.PDF_ERROR:
      return {
        ...state,
        PDFloading: false,
        PDFdata: action.error
      };
      case actionTypes.PDF_CREATE_INIT:
      return {
        ...state,
        PDFloading: true,
        PDFDownload:null
      };
    case actionTypes.PDF_CREATE_SUCCESS:
      return {
        ...state,
        PDFloading: false,
        PDFDownload: action.payload
      };
    case actionTypes.PDF_CREATE_ERROR:
      return {
        ...state,
        PDFloading: false,
        PDFDownload: action.error
      };
    default:
      return state;
  }
}
