import React,{ useState,useEffect } from 'react';
interface Props{
  pdfList
    hanldleClickPage
    selectImg
    setSelectImg
    selectPage
   }

const LeftSideBar: React.FC<Props> = (props: Props) =>{
  const [length, setLength] = useState(0);
  
  useEffect(() => {
    if(props.selectImg !== undefined){
      if(props.pdfList !== undefined){
        if(props.pdfList.length !== 0 ){
          setLength(props.pdfList.length);
        }
      }
  }

  },[props, props.pdfList])
  
  return (
    <div className="screen-left-sideBar" >
    <div className="page-count">페이지 {length}</div>
    <div className="flex-center screen-left-sideBar-scroll" style={{flexDirection:"column"}}> 
    {props.pdfList !== undefined ? props.pdfList.map((item,index)=>{ 
      return(
        <div key={index} className={item.id === props.selectPage.id ? 'MovableList select' : 'MovableList'} id={`MovableList-${item.id}`} onClick={(e) => props.hanldleClickPage(e,item.number,item.id)}>
          <img src={item.image} alt={item.image} onClick={() => props.setSelectImg(item.image)}/>
          <p>{item.number}</p>
          </div>
          )}) : <></>}
      </div>
    </div>
  )
  
};
export default LeftSideBar