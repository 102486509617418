export const PDF_INIT = 'PDF_INIT' as const;
export const PDF_ERROR = 'PDF_ERROR' as const;
export const PDF_SUCCESS = 'PDF_SUCCESS' as const;
export const PDF_CREATE_INIT = 'PDF_CREATE_INIT' as const;
export const PDF_CREATE_ERROR = 'PDF_CREATE_ERROR' as const;
export const PDF_CREATE_SUCCESS = 'PDF_CREATE_SUCCESS' as const;

export function PDFInit(payload) {
  return {
      type: PDF_INIT,
      payload
  };
}

export function PDFError(error) {
  return {
      type: PDF_ERROR,
      error,
  };
}

export function PDFSuccess(payload) {
  return {
      type: PDF_SUCCESS,
      payload
  };
}
export function PDFCreateInit(payload) {
  return {
      type: PDF_CREATE_INIT,
      payload
  };
}

export function PDFCreateError(error) {
  return {
      type: PDF_CREATE_ERROR,
      error,
  };
}

export function PDFCreateSuccess(payload) {
  return {
      type: PDF_CREATE_SUCCESS,
      payload
  };
}
export type GetPDFActionsType =
  | ReturnType<typeof PDFInit>
  | ReturnType<typeof PDFError>
  | ReturnType<typeof PDFSuccess>
  | ReturnType<typeof PDFCreateInit>
  | ReturnType<typeof PDFCreateError>
  | ReturnType<typeof PDFCreateSuccess>