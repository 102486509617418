import React,{ useState, useEffect,useRef} from 'react';

interface Props{
    index
    item
    handleClickPartialSetting
    handleClickDeleteModal
    KeyPressSectionTitle
    selectSectionTool
    setSelectSectionTool
    isPartialSetting
    setIsPartialSetting
    partialRef
 }
 
const LabCodeItem: React.FC<Props>   = (props: Props) =>{
  const [isInputState, setIsInputState] = useState(false);
  const itemInputRef = useRef<HTMLInputElement | null>(null);
  const [itemInput,setItemInput] = useState(props.item.title)
  const itemRef = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
  useEffect(() => {
    
    const handleCloseModal= (e: React.BaseSyntheticEvent | MouseEvent) => {
      // if (!itemRef.current?.contains(e.target)) {
      // props.setSelectSectionTool(false);
      
      // }
      if(!props.partialRef.current?.contains(e.target) &&!itemRef.current?.contains(e.target)){
        props.setSelectSectionTool(false);
        props.setIsPartialSetting(false);
      }
     }
     
     document.addEventListener('click',handleCloseModal,true);
    return () => {
      document.addEventListener('click',handleCloseModal,true);
    }
  }, [itemRef])
  
  return (
      <div ref={itemRef} className={ props.index === props.selectSectionTool ? 'labCode-list-item select' : 'labCode-list-item'} key={props.index} onClick={() => props.setSelectSectionTool(props.index)}>
      <div className='labCode-list-item-input' onDoubleClick={() => setIsInputState(true)} onBlur={()=>setIsInputState(false)}>
      {isInputState ? 
        <input ref={itemInputRef} defaultValue={itemInput} onChange={e=>setItemInput(e.target.value)} onKeyPress={(e) => props.KeyPressSectionTitle(e,itemInput,props.item.id)}/>
        :<div style={{paddingLeft: 8}} >{props.item.title}</div>
      }
      </div>
        <div className='labCode-list-item-icon' style={{display:(props.isPartialSetting && props.index === props.selectSectionTool) ? 'flex' : 'none'}} >
          <button className="tooltip-btn" onClick={() => window.open(`https://dev.labcode.kr/products/edit/${props.item.id}/defaultInfo/defaultInfo`,"_blank")}><div className='icon-link' /><div className='tooltip tooltip-center' style={{right: -35}}>관리자 페이지 이동</div></button>
          <button className="tooltip-btn" onClick={() => props.handleClickDeleteModal(props.item.id)}><div className='trash' /><div className='tooltip tooltip-center' style={{right: -5}}>삭제</div></button>
          <button className="setting-open-btn tooltip-btn" onClick={() => props.handleClickPartialSetting(props.item)} style={{background: props.isPartialSetting && props.index === props.selectSectionTool ? "#252a35" : ""}}><div className='more'/>
            <div className='tooltip tooltip-right'>개별 설정</div>
         </button>
        </div>
      </div>
    )
};
export default LabCodeItem