import { put, all, call, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import  * as actionTypes from './actions';
import Cookies from 'universal-cookie';

const cookies = new Cookies(); 
const REACT_APP_BASIC_URI : string = (`${process.env.REACT_APP_BASIC_URI}users` as string);

//TODO 로그인
 function* login (payload) {
  try {
  //TODO 서버 연동
    const token: AxiosResponse = yield call(() => axios.post(`${REACT_APP_BASIC_URI}/sign-in`, payload.payload));
    cookies.set('token',token.data.result.token) //TODO 로그인 완료 후 토큰을 쿠키에 삽입
    yield put(actionTypes.loginSuccess());
  } catch(err:any) {
    alert('이메일 또는 비밀번호를 다시 한번 확인해주세요.');
    yield put(actionTypes.loginFailure(err));
  }
}

//TODO 로그아웃
function* logout() {
  try {
    yield put(actionTypes.logoutSuccess());
     cookies.remove('token');//TODO 쿠키 제거
  } catch(err:any) {
    yield put(actionTypes.logoutFailure(err));
  }
}


export function* getLoginSaga() {
  yield all([
    takeLatest(actionTypes.LOGIN_REQUEST, login),
    takeLatest(actionTypes.LOGOUT_REQUEST, logout),
  ]); 
}