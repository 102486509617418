import React,{ useState, useEffect, useRef} from 'react';

interface Props{
  open
  handleClickPartialSetting
  settingData
  settingSave
  selectSectionItem
  setSelectSectionItem
  sectionItem
  partialRef
 }
const PartialSetting : React.FC<Props> = (props: Props) =>{
  const [resetBtn, setResetBtn] = useState(false);
  const [embeddingOption, setEmbeddingOption] = useState(true);
  const [channelOption, setChannelOption] = useState(true);
  const channelEl = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
  const embeddingEl = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
  //TODO 임배딩 리스트 클릭
  const embeddingOptionClick = (value) => {
    settingChange("embedding",value)
    setEmbeddingOption(false)
  }
  //TODO 채널 리스트 클릭
  const channelOptionClick = (value) => {
    settingChange("channel",value)
    setChannelOption(false)
  }
    //TODO 셋팅 값 변경
  const settingChange = (title,value) => {
  
  switch (title) {
    case "scale":
      props.setSelectSectionItem({...props.selectSectionItem,scale:value});
    break;
    case "alpha":
      props.setSelectSectionItem({...props.selectSectionItem,alpha:value});
    break;
    case "url":
      props.setSelectSectionItem({...props.selectSectionItem,url:value});
    break;
    case "embedding":
      props.setSelectSectionItem({...props.selectSectionItem,embedding:value});
    break;
    case "channel":
      props.setSelectSectionItem({...props.selectSectionItem,channel:value});
    break;
    default:
  }
  setResetBtn(false);
  }
  
  useEffect(() => {
  //select list open 중 다른 화면 클릭시 close
    const handleCloseModal= (e: React.BaseSyntheticEvent | MouseEvent) => {
      if (!channelEl.current?.contains(e.target)) setChannelOption(false);
      if (!embeddingEl.current?.contains(e.target)) setEmbeddingOption(false);
     }
     
     document.addEventListener('click',handleCloseModal,true);
    return () => {
      document.addEventListener('click',handleCloseModal,true);
    }
  }, [channelEl,embeddingEl])
  
  
  const settingReset = () => {
    props.setSelectSectionItem({
    ...props.selectSectionItem,
    alpha:props.sectionItem.alpha,
    scale:props.sectionItem.scale,
    url:props.sectionItem.url,
    embedding:props.sectionItem.embedding,
    channel:props.sectionItem.channel
  });
  setResetBtn(true);
    }
    
    useEffect(() => {

    if(props.selectSectionItem.url !== props.sectionItem.url){
      setResetBtn(false);
    }else if(props.selectSectionItem.embedding !== props.sectionItem.embedding){
      setResetBtn(false);
    }else if(props.selectSectionItem.channel !== props.sectionItem.channel){
      setResetBtn(false);
    }else if(props.selectSectionItem.scale !== props.sectionItem.scale){
      setResetBtn(false);
    }else if(props.selectSectionItem.alpha !== props.sectionItem.alpha){
      setResetBtn(false);
    }else{
      setResetBtn(true);
    }
    },[props.sectionItem,props.selectSectionItem])
  return (
      <div className='setting' ref={props.partialRef} style={{display: props.open ? "block" : "none",top:267}}>
        <div className='setting-title'><div>개별 설정</div> <div onClick={props.handleClickPartialSetting} style={{cursor:'pointer'}}><div className='close' /></div></div>
        <div className='setting-content'>
          <div className="setting-text">링크</div>
          <div className="setting-item"><input type="text" defaultValue={"https://www.naver.com"} onChange={e => settingChange("url",e.target.value)}/><a href={props.selectSectionItem.url} rel="noreferrer" target="_blank" ><div className='url' /></a></div>
          <div className="setting-text">임베딩 버전</div>
          <div className="setting-item">
          <div className="select">
            <div className="select-btn" onClick={() => setEmbeddingOption(true)}>
            v {props.selectSectionItem.embedding}
            <div className='select-arrow' />
            </div>
            <div className="optionGroup" ref={embeddingEl} style={{display:embeddingOption ? 'block' : 'none'}}>
            {props.settingData?.embedding.map((item, index) => {
              return (
                <div className="option" onClick={() => embeddingOptionClick(item.value)} key={index}> {item.label} {props.selectSectionItem.embedding === item.value && <div className='check' />}
                </div>
              )
              })}
          </div>
            </div>
           
          </div>
          <div className="setting-text">적용기술</div>
          <div className="setting-item">
            <div className="select" >
            <div className="select-btn" onClick={() => setChannelOption(true)}>
            {props.settingData?.channel.map((item, index) => {
            if(item.value === props.selectSectionItem.channel)
              return(item.label)
            })}
            <div className='select-arrow' />
            </div>
            <div className="optionGroup" ref={channelEl} style={{display:channelOption ? 'block' : 'none'}}>
            {props.settingData?.channel.map((item, index) => {
              return (
                <div className="option" onClick={() => channelOptionClick(item.value)} key={index}> {item.label} {props.selectSectionItem.channel === item.value && <div className='check' />}
                </div>
              )
              })}
            </div> 
            </div>
           
          </div>
          <div className="setting-code" style={{marginTop:32}}> <div className="setting-text">코드크기 </div><input type="number" value={props.selectSectionItem.scale} onChange={e => settingChange('scale',Number(e.target.value))} /></div>
          <div className="setting-value">
          {props.settingData?.scale.map((item, index) => {
              return (
                <div className={props.selectSectionItem.scale +2 > item.value && props.selectSectionItem.scale <= item.value ? "setting-btn selected" :  "setting-btn"} onClick={() => settingChange('scale',item.value)} key={index}>{item.label} {item.value}</div>
              )
              })}
          </div>
          <div className="setting-code"> <div className="setting-text">코드세기 </div><input type="number" value={props.selectSectionItem.alpha} onChange={e => settingChange("alpha",Number(e.target.value))}/></div>
          <div className="setting-value">
          {props.settingData?.alpha.map((item, index) => {
              return (
                <div className={props.selectSectionItem.alpha +2 > item.value && props.selectSectionItem.alpha <= item.value ? "setting-btn selected" :  "setting-btn"} onClick={() => settingChange('alpha',item.value)} key={index}>{item.label} {item.value}</div>
              )
              })}
          </div>
          
        </div>
        <div className='flex-center setting-actions'>
            <button className='setting-reset' disabled={resetBtn} onClick={() => settingReset()}>초기화</button>
            <button className='setting-save' disabled={resetBtn} onClick={() => props.settingSave()}>저장</button>
          </div>
      </div>
    
  )
  
};
export default PartialSetting