import React from 'react';
import 'src/assets/scss/reset.scss'
import 'src/assets/scss/Components.scss'
interface Props{
    open
    ExportModalBtn
    numPages;
}

const ExportModal: React.FC<Props> = (props: Props) =>{
   

  return (
    <div className="modal-export-shadow" 
    style={{display :props.open ? "flex" : "none"}}
    >
       <div className="modal-export">
        
        <div className='modal-img'><div className='dots'><div className='exportImg' /></div></div>
            <div className="modal-title">
            </div>
          
        </div>
    </div>
  );
};


export default ExportModal 