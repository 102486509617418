import { put, all, call, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import  * as actionTypes from './actions';

import {getToken} from 'src/utils/Cookies/Cookies';

const REACT_APP_BASIC_URI : string = (`${process.env.REACT_APP_BASIC_URI}sections` as string);

//TODO 세션 리스트 가져오기
function* sectionsCallWorker ({payload}) {
  const config = getToken();
  try {
    const sections: AxiosResponse = yield call(() => axios.get(`${REACT_APP_BASIC_URI}`, config));
    yield put(actionTypes.sectionSuccess(sections.data));
  } catch(err:any) {
    yield put(actionTypes.sectionError(err));
  }
}

//TODO 세팅 값 가져오기
function* settingCallWorker () {
  const config = getToken();
  try {
    const setting: AxiosResponse = yield call(() => axios.get(`${REACT_APP_BASIC_URI}/setting`, config));
    yield put(actionTypes.sectionSettingSuccess(setting.data));
  } catch(err:any) {
    yield put(actionTypes.sectionSettingError(err));
  }
}

//TODO 세션 만들기
function* sectionCreateWorker({ payload }) {
  const config = getToken();
  try {
    
    yield call(() => axios.post(`${process.env.REACT_APP_BASIC_URI}sections`,payload,config));
    const sections: AxiosResponse = yield call(() => axios.get(`${REACT_APP_BASIC_URI}`,config));
    yield put(actionTypes.sectionSuccess(sections.data));
  } catch (err:any) {
    yield put(actionTypes.sectionCreateError(err.response.data));
  }
}

//TODO 세션 정보 수정
function* sectionUpdateWorker(payload) {
  const config = getToken();
  try {

    let updateJson;
    let URL
    if(payload.payload.key === "title"){
      updateJson={title:payload.payload.title}
      URL = `${REACT_APP_BASIC_URI}/${payload.payload.id}/title`;
    }else{
      updateJson={
        url: payload.payload.url,
        embedding: payload.payload.embedding,
        channel: payload.payload.channel,
        scale: payload.payload.scale,
        alpha: payload.payload.alpha,
      };
      URL=`${REACT_APP_BASIC_URI}/${payload.payload.id}`;
    }
    const updateBook: AxiosResponse = yield call(() => axios.patch(URL,updateJson,config));
    yield put(actionTypes.sectionUpdateSuccess(updateBook.data));
    const sections: AxiosResponse = yield call(() => axios.get(REACT_APP_BASIC_URI,config));
    yield put(actionTypes.sectionSuccess(sections.data));
  } catch (err:any) {
    yield put(actionTypes.sectionUpdateError(err));
  }
}

//TODO 세션 삭제
function* sectionDeleteWorker(payload) {
  const config = getToken();
  try {
    yield call(() => axios.delete(`${REACT_APP_BASIC_URI}/${payload.payload}`,config));
    const sections: AxiosResponse = yield call(() => axios.get(REACT_APP_BASIC_URI,config));
    yield put(actionTypes.sectionSuccess(sections.data));
  } catch (err:any) {
    yield put(actionTypes.sectionDeleteError(err));
  }
}

export function* getSectionsSaga() {
  yield all([
    takeLatest(actionTypes.SECTION_INIT as any , sectionsCallWorker),
    takeLatest(actionTypes.SECTION_SETTING_INIT , settingCallWorker),
    takeLatest(actionTypes.SECTION_CREATE_INIT as any, sectionCreateWorker), 
    // 타입 오버라이딩 에러로 any 타입 삽입
    takeLatest(actionTypes.SECTION_UPDATE_INIT , sectionUpdateWorker),
    takeLatest(actionTypes.SECTION_DELETE_INIT , sectionDeleteWorker),
    
  ]); 
}