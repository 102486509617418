import { put, all, call, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import  * as actionTypes from './actions';
import Cookies from 'universal-cookie';
import {getToken} from 'src/utils/Cookies/Cookies';

const REACT_APP_BASIC_URI : string = (`${process.env.REACT_APP_BASIC_URI}pdf` as string);
const cookies = new Cookies()

//TODO PDF 리스트 가져오기 (PDF 첫 삽입시 사용)
function* PDFCallWorker (payload) {
  
  const config = getToken();
  try {
    const PDF: AxiosResponse = yield call(() => axios.get(`${REACT_APP_BASIC_URI}?book=${payload.payload}`, config));
    yield put(actionTypes.PDFCreateSuccess(PDF.data));
  } catch(err:any) {
    yield put(actionTypes.PDFCreateError(err));
  }
}

//TODO PDF 삽입 (multipart/form-data)
function* PDFCreateWorker (payload) {
  
  const token = cookies.get('token');
  const config = {
    headers: { Authorization: `Bearer ${token}`,'Content-Type': 'multipart/form-data'}
  }
  try {
    const PDF: AxiosResponse = yield call(() => axios.post(REACT_APP_BASIC_URI,payload.payload, config));
    yield put(actionTypes.PDFSuccess(PDF.data));
    return PDF
  } catch(err:any) {
    yield put(actionTypes.PDFError(err));
  }
}

export function* getPDFSaga() {
  yield all([
    takeLatest(actionTypes.PDF_INIT , PDFCallWorker),
    takeLatest(actionTypes.PDF_CREATE_INIT , PDFCreateWorker)
  ])
};